<template>
  <v-dialog v-model="isView" max-width="600px">
    <v-form ref="form">
      <v-card class="editorPadding">
        <Header @close="close" />

        <v-card-title> {{ $t("login.findPw.title") }} </v-card-title>
        <v-card-text>
          <v-row class="mb-10">
            <v-col>
              <span v-html="$t('login.findPw.subTitle')"></span>
              <!-- Did you forget the password?<br />
              Please enter your login id (email address),<br />
              and we will issue a temporary password to the email you entered.<br />
              If there's any problem, please call to Office -->
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="2" class="row-title"> Name</v-col>
            <v-col cols="4">
              <v-text-field
                ref="firstName"
                label="First Name"
                v-model="reqData.firstName"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                ref="lastName"
                label="Last Name"
                v-model="reqData.lastName"
              />
            </v-col>
          </v-row> -->
          <v-row>
            <!-- <v-col cols="2" class="row-title"> Login Id</v-col> -->
            <!-- :rules="[emailRules.required, emailRules.email]" -->
            <!-- :rules="[$t('login.rule.emailReq'), emailRules.email($t('login.rule.emailType'))]" -->
            <v-col cols="12">
              <v-text-field
                :label="$t('login.inputID')"
                ref="refLoginId"
                v-model="targetModel.loginId"
                :rules="[emailRules.required, emailRules.email]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
          <v-btn color="primary" depressed large @click="Submit">
            {{ $t('common.button.resetPw') }}
          </v-btn>
          <v-btn color="primary" outlined large @click="close"> {{ $t('common.button.cancel') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Header from "./comp/Header.vue";
import { mapActions, mapGetters } from "vuex";
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rulesTest.js");

export default {
  components: { Header },
  data() {
    return {
      isView: false,
      targetModel: {
        endpoint: "findPw",
        loginId: "",
      },
      emailRules: rulesService.emailRules(),
    };
  },
  methods: {
    Open() {
      this.isView = true;
      this.targetModel.loginId = "";
      setTimeout(() => {
        this.$refs.refLoginId.focus();
      }, 500);
    },
    close() {
      this.isView = false;
    },
    async Submit() {
      if (!this.$refs.form.validate()) return;

      if (!confirm("Would you like to reset your password?")) return;
      this.saveData();
    },
    saveData() {
      console.log("saveData", this.targetModel);
      this.saveDataPreLoginQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        console.log("res", res);
        if (!res.isPresent) {
          this.$helper.showTostMessage("", "User does not exist", "error");
          this.$refs.refLoginId.select();
          return;
        }
        if (!res.complete) {
          this.$helper.showTostMessage("", "An error occurred", "error");
          this.$refs.refLoginId.select();
          return;
        }
        this.$helper.showTostMessage("", "Sent~", "success");
        this.isView = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataPreLoginQuery"]),
  },
};
</script>

<style lang="scss">
.row-title {
  font-size: 16px;
  font-weight: 700;
}
</style>
