import i18n from '@/lang/lang';

export const emailRules = () => {
    return {
        // required: (value) => !!value || "Email is Required",
        required: (value) => !!value || i18n.t('login.rule.emailReq'),
        counter: (value) => value && value.length <= 100 || "Max 100 characters",
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || i18n.t('login.rule.emailType');
        },
    }
};